
import React from "react"
import Layout from "../../components/Layout"
import { Link } from "gatsby"
import Seo from "../../components/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Blog = ({pageContext}) => {  
  const posts  = pageContext.allPosts  
  const options = pageContext.postSearchData.options

  return (
    <Layout posts={posts} engine={options} placeholder="Search">
      <Seo
        title="Marco Gomes"
        description="Web Developer"
      />

      <section className="article blog">
        <header>
          <h2>Articles</h2>
        </header>

        <section>
        { posts.map( (post, index ) => (

            <Link to={post.uri} key={post.id}>
                <div className="article__item">
                  <div>
                    { post.featuredImage && 
                    <GatsbyImage image={ getImage( post.featuredImage.node.imageFile )} alt={post.featuredImage.node.altText}  className="article__item__img"/>
                    }
                  </div>
                  <div>
                    <h2 className="article__item__title"  dangerouslySetInnerHTML={{ __html: post.title }}></h2>
                    
                  </div>
                </div>
            </Link> 
            ))
        }
    
        </section>
      </section>


   
    </Layout>
  )
}

export default Blog